import Swiper from 'swiper/bundle';

class Categories {
    constructor(element) {
        this.$categories = element;
        this.$categoriesSliderElement = this.$categories.querySelector('[data-categories="slider"]');
        this.$categoriesSlides = this.$categories.querySelectorAll('[data-categories="slide"]');
        this.$categoriesSliderPagination = this.$categories.querySelector('[data-categories="pagination"]');
        this.swiperInstance = null;
        this.initialize();
    }

    initialize () {
        if (this.$categoriesSlides.length > 1) {
            if (window.mediaMatch[1024].matches) {
                this.destroySlider();
            } else {
                this.initCarouselSlider();
            }

            window.mediaMatch[1024].addEventListener('change', (match) => {
                if (match.matches) {
                    this.destroySlider();
                } else {
                    this.initCarouselSlider();
                }
            });
        }
    }

    initCarouselSlider () {
        if (this.$categoriesSliderElement && !this.swiperInstance) {
            this.swiperInstance = new Swiper(this.$categoriesSliderElement, {
                autoHeight: true,
                slidesPerView: 'auto',
                speed: 500,
                pagination: {
                    el: this.$categoriesSliderPagination,
                    clickable: true
                }
            });
            this.$categoriesSliderPagination.classList.remove('categories-pagination--is-hidden');
        }
    }

    destroySlider () {
        if (this.swiperInstance) {
            this.swiperInstance.destroy(true, true);
            this.swiperInstance = null;
            this.$categoriesSliderPagination.classList.add('categories-pagination--is-hidden');
        }
    }
}

export { Categories };
